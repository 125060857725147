<template lang="pug">
configCreateValidator(@validated="checkValidatorBeforeSend" v-slot="validator")
  v-dialog(
    max-width="400px"
    :value="showing"
    @input="updateShowing"
  )
    template(v-slot:activator="{on}")
      v-btn(small color='primary'
        @click="toggle"
        :disabled="disabled"
        outlined
      ).mr-2 {{ title }}

    modal-body(
      :editData="formData"
      :key="showing"
      :formErrors="formErrors"
      :showing="showing"
      @back="toggle"
      :title="title"
    )
      template(v-slot:body)
        v-col.pt-0.pb-0(cols="12")
          filter-autocomplete(
            v-model="formData.city"
            :getItems="getCourseCities"
            item-text="name"
            item-value="ID"
            label="Course location"
            :error="formErrors.hasError('city')"
            :error-messages="formErrors.fields.city"
          )
      template(v-slot:actions="modalProps")
        v-spacer
        v-btn(outlined width="80" @click="toggle").btn-common Cancel
        v-btn(
          color="primary"
          width="80"
          :loading="processing"
          @click="validator.validationBeforeSend({data: {...modalProps.formData, city: formData.city}})"
          data-test="cm-create-submit"
        ).btn-common Add

</template>

<script>
import FormErrors from '@/util/form-errors'
import { CM_EXAM_CONFIG } from '@/app/admin/modules/course_managment/classes/CMExamConfig'
import errorsMixin from '@/mixins/errors.mixin'
import actionService from "@/app/admin/modules/candidates/core/candidates-actions-service.js"
import { mapActions } from "vuex"
import QuickReserve from "../core/models/candidatesQuickReserve"

export default {
  name: 'QuickReserve',

  mixins: [errorsMixin],

  props: {
    disabled: Boolean,
    activeItems: Array,
    title: {
      type: String,
      default: 'Quick reserve'
    },
    isExchange: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    showing: false,
    formErrors: new FormErrors(),
    formData: new QuickReserve({
      [CM_EXAM_CONFIG.EXAM_TIME_FROM]: '13:20',
      [CM_EXAM_CONFIG.EXAM_TIME_TO]: '15:40'
    }),
    processing: false,
    svc: new actionService()
  }),

  methods: {
    ...mapActions({
      getCourseCities: 'crmCourseCities/list'
    }),

    toggle() {
      this.showing = !this.showing
    },

    updateShowing(val) {
      this.showing = val
    },

    checkValidatorBeforeSend(payload) {
      this.formErrors = payload.validator
      if(!this.formErrors.isValid()) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      this.send(payload)
    },

    async send({data}) {
      try {
        this.processing = true
        let ids = this.activeItems.map(item => item.ID)
        let res = await this.svc.quickReserve(this.formData.city, ids, {
          ...data,
          isExchange: this.isExchange
        })
        this.$notify({text: 'Start reservation', type: 'success'})
        this.toggle()
        this.$emit('send', res)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    }
  },

  watch: {
    showing() {
      if(this.showing) {
        if(this.$route.query.city) this.formData.city = +this.$route.query.city
      }
    }
  },
  components: {
    modalBody: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamModalBody.vue'),
    configCreateValidator: () => import('./CandidatesQuickReserveValidator.vue'),
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
  }
}
</script>
